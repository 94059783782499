export const Config = {
  auth: "",
  api: "/api/v1", //data.api
  gaId: process.env.NEXT_PUBLIC_GAID,
  env: process.env.NEXT_PUBLIC_ENV,
  isProd: process.env.NEXT_PUBLIC_ENV && process.env.NEXT_PUBLIC_ENV === "prod",
  gToken: process.env.NEXT_PUBLIC_GACAPTCHA,
  wordpressEnabled: process.env.NEXT_PUBLIC_WORDPRESS_ENABLED === "true" || false,
  wordpressApi: process.env.NEXT_PUBLIC_WORDPRESS_HEADLESS || "",
  razorpayKey: process.env.NEXT_PUBLIC_RAZORPAY_KEY,
  adsenseClient: process.env.NEXT_PUBLIC_ADSENSE_CLIENT,
  adsenseSlots: {
    home: process.env.NEXT_PUBLIC_ADSENSE_SLOT_HOME,
    blog: process.env.NEXT_PUBLIC_ADSENSE_SLOT_BLOG,
  },
  lazyloadCaptcha: !!process.env.NEXT_PUBLIC_LAZY_LOAD_CAPTCHA && process.env.NEXT_PUBLIC_LAZY_LOAD_CAPTCHA === "true",
  isPantryEnabled: !!process.env.NEXT_PUBLIC_PANTRY_ENABLED && process.env.NEXT_PUBLIC_PANTRY_ENABLED === "true",
};
