import { render, unmountComponentAtNode } from "react-dom";
import Snackbar from "@components/utils/Snackbar";

export const toast = {
  remove: () => {
    unmountComponentAtNode(document.getElementById("toast-container") as HTMLElement);
    toast.currentToast = false;
    if (toast.timeout) {
      clearTimeout(toast.timeout);
      toast.timeout = null;
    }
  },
  currentToast: false,
  timeout: null,
  notify: (message: string, durationType: "long" | "short" = "short") => {
    let duration = durationType === "long" ? 5 : 3;
    if (toast.currentToast) {
      toast.remove();
    }
    render(<Snackbar text={message} />, document.getElementById("toast-container"));
    toast.currentToast = true;
    toast.timeout = setTimeout(toast.remove, duration * 1000);
  },
};
