import React, { createContext, useMemo, useReducer } from "react";
import { CommonContextReducerState, CurrentUserInfo, UserModalType } from ".";
import { commonContextReducer, getInitialCommonContextState } from "./commonContextReducer";
import { CommonContextType } from "./types";

const CommonContext = createContext<CommonContextType>({
  ...getInitialCommonContextState(),
  setMounted: undefined,
  setUserInfo: undefined,
  loadState: undefined,
  resetState: undefined,
  showUserModal: undefined,
  closeUserModal: undefined,
});

const CommonContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [commonContext, dispatch] = useReducer(commonContextReducer, getInitialCommonContextState());

  const memoizedContext: CommonContextType = useMemo(
    () => ({
      ...commonContext,
      setMounted: (payload: boolean) => dispatch({ type: "SET_MOUNTED", payload }),
      setUserInfo: (payload: CurrentUserInfo | null) => dispatch({ type: "SET_USER_INFO", payload }),
      loadState: (payload: Partial<CommonContextReducerState>) => dispatch({ type: "LOAD_STATE", payload }),
      resetState: () => dispatch({ type: "RESET_STATE" }),
      showUserModal: (type: UserModalType) => dispatch({ type: "SET_USER_MODAL_INFO", payload: { type, show: true } }),
      closeUserModal: () => dispatch({ type: "SET_USER_MODAL_INFO", payload: { type: "login", show: false } }),
    }),
    [commonContext]
  );

  return <CommonContext.Provider value={memoizedContext}>{children}</CommonContext.Provider>;
};

export { CommonContextProvider, CommonContext };

CommonContext.displayName = "CommonContext";
