import { Analytics, getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getPerformance } from "firebase/performance";
import { fetchAndActivate, getAll, getRemoteConfig, getValue, RemoteConfig } from "firebase/remote-config";
import useSWR, { mutate } from "swr";
import { Config } from "./utils/Config";

const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_APIKEY,
  authDomain: process.env.NEXT_PUBLIC_AUTHDOMAIN,
  projectId: process.env.NEXT_PUBLIC_PROJECTID,
  databaseURL: process.env.NEXT_PUBLIC_DATABASEURL,
  storageBucket: process.env.NEXT_PUBLIC_STORAGEBUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_MESSAGINGSENDERID,
  appId: process.env.NEXT_PUBLIC_APPID,
  measurementId: process.env.NEXT_PUBLIC_MEASUREMENTID,
};

const firebaseApp = initializeApp(firebaseConfig);
let firebaseAnalytics: Analytics;
let perf;
let remoteConfig: RemoteConfig;
if (typeof window !== "undefined") {
  firebaseAnalytics = getAnalytics(firebaseApp);
  perf = getPerformance(firebaseApp);
  remoteConfig = getRemoteConfig(firebaseApp);
}

const defaultConfig: any = {
  bulk_request_languages: ["ENGLISH", "HINDI"],
  bulk_request_languages_default_index: 1,
  rating_count_threshold: 1,
  good_feedback_rating_threshold: 3,
  support_number: 1323,
  support_email: "ecatering.customercare@irctc.com",
  payment_mode_mappings: {
    default: "unknown method",
    CC: "credit card",
    DC: "debit card",
    NB: "net banking",
    netbanking: "net banking",
    UPI: "UPI",
    upi: "UPI",
    PPI: "Paytm wallet",
    PHONEPE: "PhonePe app",
  },
  cuisine_mappings: {
    SOUTH_INDIAN: "South Indian",
    PUNJABI: "Punjabi",
    NORTH_INDIAN: "North Indian",
    MUGHALAI: "Mughlai",
    BENGALI: "Bengali",
    GOAN: "Goan",
    TAMIL: "Tamil",
    ANDHRA: "Andhra",
    KERALA: "Kerala",
    INDIAN_CHINESE: "Indian Chinese",
    CHINESE: "Chinese",
    AWADHI: "Awadhi",
    MALAYSIAN: "Malaysian",
    MAHARASHTRIAN: "Maharashtrian",
    TIBETAN: "Tibetan",
    SRI_LANKAN: "Sri Lankan",
    SIKKIMESE: "Sikkimese",
    TASTE_OF_BIHAR: "Taste of Bihar",
  },
  order_status_mappings: {
    ORDER_PLACED: "Scheduled",
    ORDER_CONFIRMED: "Confirmed by Restaurant",
    ORDER_PREPARING: "Being Prepared",
    ORDER_PREPARED: "Food Prepared",
    ORDER_OUT_FOR_DELIVERY: "Order Picked-up",
    ORDER_DELIVERED: "Order Arrived",
    ORDER_CANCELLED: "Order Cancelled",
    ORDER_UNDELIVERED: "Delivery Failed",
    ORDER_PARTIALLY_DELIVERED: "Partly Delivered",
    ORDER_PENDING: "Payment Pending",
    PICKUP_BOY_ALLOCATED: "Awaiting Pickup",
    OUT_FOR_PICKUP: "Awaiting Pickup",
    ORDER_PICKED_UP: "In Transit",
    DELIVERY_BOY_ALLOCATED: "In Transit",
    OUT_FOR_DELIVERY: "Order Picked-up",
  },
  next_order_status_mappings: {
    ORDER_PLACED: "Order will be Scheduled",
    ORDER_CONFIRMED: "Order will be Confirmed by restaurant",
    ORDER_PREPARING: "Order will start Being Prepared",
    ORDER_PREPARED: "Food Prepared",
    ORDER_OUT_FOR_DELIVERY: "Order Picked-up",
    ORDER_DELIVERED: "Order Arrived",
    ORDER_CANCELLED: "Order Cancelled",
    ORDER_UNDELIVERED: "Delivery Failed",
    ORDER_PARTIALLY_DELIVERED: "Partly Delivered",
    ORDER_PENDING: "Payment Pending",
    PICKUP_BOY_ALLOCATED: "Awaiting Pickup",
    OUT_FOR_PICKUP: "Awaiting Pickup",
    ORDER_PICKED_UP: "In Transit",
    DELIVERY_BOY_ALLOCATED: "In Transit",
    OUT_FOR_DELIVERY: "Order Picked-up",
  },
  placing_order: {
    cod: "Placing your order",
    prepaid: "Placing your order, you'll be taken to payment page",
  },
  coupon_tnc: {
    coupon_tnc_title: "Conditions for issuance and usage of coupon",
    coupon_tnc_text:
      "• The coupon code is non transferable to another user.\n• Only one coupon can be used at one time.\n• Applicable on both prepaid and POD orders.\n• Validity of coupon is for 6 months from the date of issue.\n• The coupon is temporarily not applicable for Domino's.\n• In case of non delivery, your coupon will be treated as unused coupon and one additional coupon will be issued.\n• In case of cancellation of order on which coupon is applied, coupon will be treated as unused.",
  },
  pnr_validation_expiration_minutes: 5,
  search_train_item_count: 5,
  search_station_item_count: 5,
  feature_flag_payment_options_note: false,
  coupon_disabled_vendors: [1190],
  banner_card_image: "",
  banner_card_image_srcset: "",
  banner_card_image_alt: "",
  banner_card_redirection: "",
  navratri_april_2022_vendor_list: [],
  rating_threshold: 0,
  show_cvc_pledge_to: 1667001600000,
  show_cvc_pledge_from: 1666877966000,
  social_share_text:
    "I just had some delicious #food from %s delivered right at my #train seat!\n\nCheck out the #FoodOnTrack app by #IRCTC #ECatering to enjoy tasty food on your train journeys!\n\nhttp://bit.ly/FoodOnTrack",
  menu_search_button_above_jumplist: true,
};

const isNumeric = (n: string) => +n === +n;
const isBoolean = (n: string) => n === "false" || n === "true";

const fetchRemoteConfig = async () => {
  if (remoteConfig) {
    try {
      await fetchAndActivate(remoteConfig);
      console.log("fetch remote config successful");
      Object.keys(getAll(remoteConfig)).forEach((key) => {
        const [value, source] = [getValue(remoteConfig, key).asString(), getValue(remoteConfig, key).getSource()];
        if (source === "remote") {
          if (value.startsWith("{") || value.startsWith("[")) {
            defaultConfig[key] = JSON.parse(value);
          } else if (isNumeric(value)) {
            defaultConfig[key] = +value;
          } else if (isBoolean(value)) {
            defaultConfig[key] = value === "true";
          } else {
            defaultConfig[key] = value;
          }
        }
      });
      if (!Config.isProd) {
        remoteConfig.settings.minimumFetchIntervalMillis = 1000 * 60 * 60 * 1;
      } else {
        remoteConfig.settings.minimumFetchIntervalMillis = 1000 * 60 * 60 * 4;
      }
      mutate("config");
    } catch (e) {
      console.log(e, "Error occurred while remote config fetch");
    }
  }
};

const useConfig = () => {
  const { data } = useSWR("config", () => ({ ...defaultConfig }), {
    initialData: { ...defaultConfig },
  });

  return data;
};

if (typeof window !== "undefined") {
  console.log("enabled firebase performance:", perf?.dataCollectionEnabled);
  fetchRemoteConfig();
}

export { firebaseApp, firebaseAnalytics, useConfig };
