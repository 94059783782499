import { PubSubEvents, StorageRef } from "@enums/common";
import { ErrorMessages } from "@enums/messages";
import { PnrResponse } from "@interfaces/index";
import { toast } from "@utils/Notification";
import axios, { AxiosError, AxiosResponse, Method } from "axios";
import { getCaptchaToken } from "../captcha-token";
import { GetAppNameFromBrowserInfo, getPnrInfo, getUserInfoFromStorage } from "../utils/Common";
import { Config } from "./Config";
import { PersistantStorage, Storage } from "./Storage";

const getQueryParamStartingCharacter = (url: string) => (url.indexOf("?") > -1 ? "&" : "?");

const tsUrl = (url: string, method: string) => {
  return method === "GET" ? url + getQueryParamStartingCharacter(url) + "_=" + Date.now() : url;
};

const currentRetryCount = () => Storage.get("RETRY_COUNT_FOR_LOGIN_AFTER_401");
const incrementRetryCount = (value?: number) => Storage.set("RETRY_COUNT_FOR_LOGIN_AFTER_401", value !== undefined ? value : currentRetryCount() + 1);
const existingAuth = () => Config.auth || PersistantStorage.get("auth");

const loginFromUserDetails = async () => {
  incrementRetryCount();
  const userInfo = getUserInfoFromStorage();
  if (userInfo) {
    const { mobile, email } = userInfo;
    await Post({ url: "/api/v2/auth/customer/login", data: { mobile, password: email }, isAuth: true });

    return true;
  } else {
    return false;
  }
};

const setPnrInfo = (data: PnrResponse, pnr: string) => {
  // Make sure the object with pnr doesn't exist before appending
  // create a new array when PNRSearchHistory is null
  // make sure the latest pnr is at 0th index
  Storage.set(StorageRef.PnrInfo + "." + pnr, data);
  Storage.set(StorageRef.CurrentPnr, pnr);

  // Storage.set(StorageRef.PNRSearchHistory + "." + pnr, [data, ...s]);
};

const loginFromPnrDetails = async () => {
  const pnr = Storage.get(StorageRef.CurrentPnr);
  // const pnrInfo = Storage.get(StorageRef.PnrInfo + "." + pnr);
  incrementRetryCount();
  if (pnr) {
    return await getPnrInfo(pnr)
      .then((data) => {
        if (data?.status === "success") {
          setPnrInfo(data, pnr);
          return true;
        } else {
          return false;
        }
      })
      .catch(() => {
        return false;
      });
  } else {
    return false;
  }
};

const handleActual401 = () => {
  Storage.del("auth");
  PersistantStorage.del("user");
  PersistantStorage.del("user_token");
  let currentPnr = Storage.get("currentPnr");
  if (currentPnr) {
    Storage.del(`stations.${currentPnr}`);
  }
  Storage.del(StorageRef.CurrentPnr);
  Storage.set("loginError", true);
  Storage.del("RETRY_COUNT_FOR_LOGIN_AFTER_401");
  return;
};

const checkIfRetryIsaAvailableAndReLogin = async ({ callbackInCaseReloginIsSuccessfull }: { callbackInCaseReloginIsSuccessfull: () => void }) => {
  if (currentRetryCount() < 2) {
    if (!(await loginFromUserDetails())) {
      if (!(await loginFromPnrDetails())) {
        checkIfRetryIsaAvailableAndReLogin({ callbackInCaseReloginIsSuccessfull });
      } else {
        return callbackInCaseReloginIsSuccessfull();
      }
    } else {
      return callbackInCaseReloginIsSuccessfull();
    }
  } else {
    return handleActual401();
  }
};

const storeAuthTokenIfUserUrl = (url: string, token: string) => {
  if (url.includes("/api/v2/customer") || url.includes("/api/v2/auth/customer/login")) {
    PersistantStorage.set("user_token", token);
    return true;
  }
  return false;
};

const registerAuthToken = (auth: string) => {
  Config.auth = auth;
  Storage.set("auth", auth);
};

const _401ValidationIgnoreUrl: Record<string, true> = {
  "/api/v2/auth/customer/login": true,
};

type RequestProps = {
  url: string;
  data?: any;
  method?: Method;
  isAuth?: boolean;
  disableErrorToast?: boolean;
  addCaptchaToken?: boolean;
  mandatoryCaptchaToken?: boolean;
};

const Request = async ({
  url,
  data,
  method = "GET",
  isAuth = true,
  disableErrorToast = false,
  addCaptchaToken = false,
  mandatoryCaptchaToken = false,
}: RequestProps) => {
  const RequestCallbackInCaseOfExpiredAuth = async () => await Request({ url, data, method, isAuth, disableErrorToast, addCaptchaToken });

  let headers: Record<string, string> = {
    "app-name": GetAppNameFromBrowserInfo(),
    // "app-name": "android_app",
  };
  if (data) {
    headers = {
      "Content-Type": "application/json",
      "app-name": GetAppNameFromBrowserInfo(),
    };
  }
  // Priority of tokens - User x-auth > Pnr x-auth > Recaptcha tokens

  // In fresh PNR search,
  // User not logged in (User x-auth, pnr x-auth doesnt exist) -> Use Recaptcha token (only if the api supports it)
  // login and signup - dont include user xauth - add recaptcha if pnr x auth doesn't exist
  // User logged in (User x-auth token exists) -> Use x auth token

  if (isAuth) {
    headers["Access-Control-Allow-Origin"] = "*";
    // const authToken = Config.auth || PersistantStorage.get("auth");
    const authToken = PersistantStorage.get("user_token") ?? Storage.get("auth");
    if (authToken && !mandatoryCaptchaToken) {
      headers["x-auth"] = authToken;
    }
  }

  let _url = url;

  try {
    // add captcha token to URL
    if ((addCaptchaToken && !headers["x-auth"]) || mandatoryCaptchaToken) {
      const token = await getCaptchaToken();
      _url = `${url}${getQueryParamStartingCharacter(url)}token=${token}`;
    }
  } catch (e) {
    console.log(e);
    toast.notify(ErrorMessages.TRY_AGAIN);
    return undefined;
  }

  try {
    const response = await axios({ url: tsUrl(_url, method), method, headers, data, timeout: 60000 });
    const auth = response.headers["x-auth"] || response.headers["X-AUTH"];
    if (auth) {
      if (!storeAuthTokenIfUserUrl(url, auth)) {
        // registerAuthToken(auth);
        // storePnrToken(auth)
        Storage.set("auth", auth);
      }
    }
    return response.data;
  } catch (error) {
    if ((error as AxiosError).code === "ECONNABORTED") {
      toast.notify("Unable to process request. Please try again");
      return undefined;
    } else {
      const response = (error as AxiosError).response as AxiosResponse<any>;
      if (response.status === 401) {
        if (!_401ValidationIgnoreUrl[url]) {
          return await checkIfRetryIsaAvailableAndReLogin({ callbackInCaseReloginIsSuccessfull: RequestCallbackInCaseOfExpiredAuth });
        }
        if (currentRetryCount() >= 2) {
          return handleActual401();
        }
      }

      const is400or500ResponseCode = response.status.toString()[0] === "4" || response.status.toString()[0] === "5";
      if (is400or500ResponseCode && !disableErrorToast) {
        const errorMessage = response.data?.message || "Something went wrong. Please try again!";
        toast.notify(errorMessage);
      }

      return response.data;
    }
  }
};

type GetProps = Pick<RequestProps, "url" | "disableErrorToast" | "addCaptchaToken" | "mandatoryCaptchaToken">;
type PostProps = GetProps & { isAuth: boolean; data: any };

export const Get = ({ url, disableErrorToast = false, addCaptchaToken = false, mandatoryCaptchaToken }: GetProps) =>
  Request({ url, disableErrorToast, addCaptchaToken, mandatoryCaptchaToken });
export const Post = ({ url, data, isAuth = true, disableErrorToast = false, addCaptchaToken = false }: PostProps) =>
  Request({
    url,
    data,
    method: "POST",
    isAuth,
    disableErrorToast,
    addCaptchaToken,
  });

export const CaptchaGet = ({ url, disableErrorToast, mandatoryCaptchaToken }: GetProps) => {
  if (typeof window !== "undefined" && window.loadingGrecaptcha) {
    PubSub.publish(PubSubEvents.LOAD_CAPTCHA);
  }
  return Get({ url, disableErrorToast, addCaptchaToken: true, mandatoryCaptchaToken });
};

export const CaptchaPost = ({ url, data, isAuth, disableErrorToast }: PostProps) => {
  if (typeof window !== "undefined" && window.loadingGrecaptcha) {
    PubSub.publish(PubSubEvents.LOAD_CAPTCHA);
  }
  return Post({ url, data, isAuth, disableErrorToast, addCaptchaToken: true });
};
