import { useContext } from "react";
import { PnrContext } from "../context";

const usePnrContext = () => {
  const pnrContextInfo = useContext(PnrContext);

  return pnrContextInfo;
};

export default usePnrContext;
