import { getCurrentPnrInfoFromPnrResponse, getOutletOrderByTimeInfo, setStationArrivalTimeInfo } from "@services/utils/Common";
import { CurrentOutletInfo, PnrContextReducerActions, PnrContextReducerState } from "./types";

const getInitialPnrReducerState = (): PnrContextReducerState => ({
  pnrInfo: {
    pnr: null,
    boardingStationInfo: null,
    stations: null,
    trainInfo: null,
    seatInfo: null,
    isValidPnr: false,
  },
  stationInfo: null,
  outletInfo: null,
});

const pnrContextReducer = (state: PnrContextReducerState, action: PnrContextReducerActions): PnrContextReducerState => {
  switch (action.type) {
    case "SET_CURRENT_PNR_INFO": {
      const { pnr, pnrResponse } = action.payload;
      if (!!pnrResponse.stations.length || !!pnrResponse.pantryInfo?.isPantryAvailable) {
        const pnrInfo = getCurrentPnrInfoFromPnrResponse(pnr, pnrResponse);
        return { ...state, pnrInfo };
      }
      return state;
    }
    case "SET_CURRENT_STATION": {
      return { ...state, stationInfo: setStationArrivalTimeInfo(action.payload) };
    }
    case "SET_CURRENT_OUTLET": {
      let outletInfo: CurrentOutletInfo = { ...action.payload, orderByTimeString: null, orderByDateString: null, orderIn24Hours: false };
      if (outletInfo.orderByTime) {
        const orderByTimeInfo = getOutletOrderByTimeInfo(outletInfo.orderByTime);
        outletInfo = { ...outletInfo, ...orderByTimeInfo };
      }
      return { ...state, outletInfo };
    }
    case "LOAD_STATE":
      return { ...state, ...action.payload };
    case "RESET_STATE":
      return { ...getInitialPnrReducerState(), ...action.payload };
  }
};

export { pnrContextReducer, getInitialPnrReducerState };
