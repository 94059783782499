import { Post } from "../utils/Request";
import Analytics from "@services/analytics";
import { EventName } from "@enums/events";

export const GetPhonePeSDK = () => PhonePe.PhonePe.build(PhonePe.Constants.Species.web);

export const GetPhonePeSSOGrantToken = async () => {
  try {
    PhonePe.PhonePe.loggingEnabled = true;
    const sdk = await GetPhonePeSDK();
    const fetchGrantTokenResponse = sdk.fetchGrantToken();
    return fetchGrantTokenResponse;
  } catch (err) {
    return err;
  }
};

export const CreatePhonePeUser = (options) => Post(`${window.IRCTC.api}/payment/phonepe/login`, options);

export const GetPhonepeOrderId = (orderId) => get(`${window.IRCTC.api}/payment/phonepe/initiate/${orderId}`);

export const PhonepeCallBack = (orderId) => get(`${window.IRCTC.api}/payment/phonepe/callback/${orderId}`);

export const openPhonepePaymentPage = async (params, success, failure) => {
  const FALLBACK_URL = "https://ecatering.irctc.co.in/confirm/" + params.orderId;
  const IMAGE_URL = "https://carbon-static.ipsator.com/static/assets/icons/android-chrome-192x192.png";
  const MERCHANT_NAME = "E-Catering";
  let metadata = [];
  const totalQuantity = params.orderItems.map((orderItem) => orderItem.quantity).reduce((prev, next) => prev + next);
  metadata.push({ Quantity: totalQuantity });
  metadata.push({ Items: params.orderItems.map((item) => item.itemName).toString() }); // comma separeted order items

  try {
    const data = await GetPhonepeOrderId(params.orderId);
    if (data.status === "success") {
      let context = data.result.data;
      PhonePe.PhonePe.loggingEnabled = true;
      const sdk = await GetPhonePeSDK();
      await sdk.openPaymentsPage(MERCHANT_NAME, context, FALLBACK_URL, IMAGE_URL, metadata);
      let phonepeCallback = await PhonepeCallBack(params.orderId);
      if (phonepeCallback.status === "failure") {
        failure && failure(phonepeCallback);
      }
      success(data);
      Analytics.track(EventName.PAYMENT_SUCCESS);
    } else {
      failure && failure(data);
      Analytics.track(EventName.PAYMENT_FAILURE);
    }
  } catch (err) {
    await PhonepeCallBack(params.orderId);
    console.log("err in phonepe open payment page", err);
    failure && failure();
    Analytics.track(EventName.PAYMENT_FAILURE);
  }
};
