import parse from "date-fns/parse";
import parseISO from "date-fns/parseISO";
import { utcToZonedTime } from "date-fns-tz";

type DateTimeFormatterReturnType = {
  dateString: string;
  dateStringNumeric: string;
  longDateString: string;
  dateInNormalFormat: string;
  timeString: string;
  timeDiffInHours: number;
  weekday: string;
  year: string;
};

const tz = "Asia/Kolkata";

/**
 *
 * THIS CONVERSION IS NECESSARY FOR DATES TO WORK ON iOS Devices/browsers
 * @param timeString = 2021-12-24 12:10 UTC
 * @returns 2021-12-24T12:10
 */
const convertTimeStringToIso8601 = (timeString: string) =>
  timeString.includes("UTC") ? timeString.replace(" UTC", "Z").replace(" ", "T") : timeString;

/**
 * Returns date string and time string in following formats
 * timeString, dateString, longDateString, dateStringNumeric,weekday,timeDiffInHours,
 */
export function dateTimeFormatter(
  dateTime: string | number[],
  month: "short" | "long" | "2-digit" = "short",
  timeFormat: 24 | 12 = 12
): DateTimeFormatterReturnType {
  let dateTimeObjRef;

  if (typeof dateTime === "string") {
    dateTimeObjRef = new Date(convertTimeStringToIso8601(dateTime));
  } else {
    // @ts-ignore
    dateTimeObjRef = new Date(...dateTime);
  }

  const dateTimeObj = utcToZonedTime(dateTimeObjRef, tz);

  const dateNow = utcToZonedTime(new Date(), tz);
  const timeDiffInHours = (dateTimeObj.getTime() - dateNow.getTime()) / (1000 * 60 * 60);

  const dateTimeInfo = new Intl.DateTimeFormat("en", {
    year: "numeric",
    month: month,
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    weekday: "long",
    hour12: timeFormat === 12,
  }).formatToParts(dateTimeObj);
  // @ts-ignore
  const dateTimeMap: Record<Intl.DateTimeFormatPartTypes, any> = {};
  dateTimeInfo.forEach((info) => {
    dateTimeMap[info.type] = info.value;
  });

  const longMonthInfo = new Intl.DateTimeFormat("en", { month: "long" }).format(dateTimeObj);

  return {
    timeString: `${dateTimeMap["hour"]}:${dateTimeMap["minute"]}${timeFormat === 12 ? " " + dateTimeMap["dayPeriod"] : ""}`,
    dateString: `${dateTimeMap["day"]} ${dateTimeMap["month"]}`,
    longDateString: `${dateTimeMap["day"]} ${longMonthInfo}`,
    dateStringNumeric: `${dateTimeMap["year"]}-${dateTimeMap["month"]}-${parseInt(dateTimeMap["day"]) < 10 ? "0" : ""}${dateTimeMap["day"]}`,
    dateInNormalFormat: `${dateTimeMap["day"]}-${dateTimeMap["month"]}-${dateTimeMap["year"]}`,
    weekday: dateTimeMap["weekday"],
    year: dateTimeMap["year"],
    timeDiffInHours,
  };
}
