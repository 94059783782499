import { EventName, EventProperty } from "@enums/events";
import { firebaseAnalytics } from "@services/globalConfig";
import { logEvent } from "firebase/analytics";
import mixpanel from "mixpanel-browser";
import moengage from "./moengage";
import { BrowserInfo } from "./utils/Common";

declare global {
  interface Window {
    lastScreenTracked: string;
    dataLayer: any;
  }
}

const { NEXT_PUBLIC_MIXPANEL_ENABLED, NEXT_PUBLIC_MIXPANEL_TOKEN, NEXT_PUBLIC_ENV } = process.env;

class AnalyticsService {
  _type: string;
  time: Date;
  _mixpanelInitialized: boolean;

  constructor() {
    this._type = "AnalyticsService";
    this.time = new Date();
    this._mixpanelInitialized = false;
    if (NEXT_PUBLIC_MIXPANEL_ENABLED === "true") {
      this.initMixpanel(NEXT_PUBLIC_MIXPANEL_TOKEN ?? "");
    }
  }

  initMixpanel(token: string) {
    if (typeof window !== "undefined") {
      let userIdentifier = localStorage.getItem("userIdentifier");
      if (!userIdentifier) {
        userIdentifier = Math.random().toString();
        localStorage.setItem("userIdentifier", userIdentifier);
      }
      if (parseFloat(userIdentifier) <= 0.1) {
        mixpanel.init(token);
        this._mixpanelInitialized = true;
      }
    }
  }

  track(name: EventName, options?: any) {
    if (typeof window !== "undefined") {
      const eventOptions: any = {
        [EventProperty.APP_NAME]: BrowserInfo.mobileCheckSoft ? "mobile web" : "desktop web",
        environment: NEXT_PUBLIC_ENV,
        ...options,
      };
      firebaseAnalytics && logEvent(firebaseAnalytics, name as any, eventOptions);
      this._mixpanelInitialized && mixpanel.track(name, eventOptions);
    }
  }

  track_page(pageUrl: string) {
    this.track(EventName.SCREEN_VIEW, { firebase_screen: pageUrl });
  }

  emitGAPageView(pageUrl: string) {
    const [url] = pageUrl.split("?");
    if (typeof window !== "undefined" && window.lastScreenTracked !== url) {
      window.lastScreenTracked = url;
      this.track_page(url);
    }
  }

  emitGAEvent(eventCategory: string, eventAction: string) {
    const pageUrl = eventAction ? `${eventCategory}/${eventAction.toLowerCase()}` : eventCategory;
    this.track_page(pageUrl);
  }

  moengageEvent() {
    return moengage().EVENT;
  }

  moengageAttribute() {
    return moengage().ATTR;
  }
}

const analyticsService = new AnalyticsService();
export default analyticsService;
