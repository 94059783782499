export enum EventName {
  SCREEN_VIEW = "screen_view",
  SEARCH = "search",
  ADD_TO_CART = "add_to_cart",
  REMOVE_FROM_CART = "remove_from_cart",
  SIGN_UP = "sign_up",
  COUPON_APPLIED = "coupon_applied",
  COUPON_FAILED = "coupon_failed",
  BEGIN_CHECKOUT = "begin_checkout",
  VIEW_ITEM_LIST = "view_item_list",
  GENERATE_LEAD = "generate_lead",
  CALL_SUPPORT = "call_support",
  SELECT_CONTENT = "select_content",
  SEND_INVOICE = "send_invoice",
  FEEDBACK_YES = "feedback_yes",
  FEEDBACK_NO = "feedback_no",
  FEEDBACK_GOOD = "feedback_good",
  FEEDBACK_BAD = "feedback_bad",
  CALL_VENDOR = "call_vendor",
  ITEM_VARIANT_SELECTED = "item_variant_selected",
  ITEM_VARIANT_DESELECTED = "item_variant_deselected",
  ITEM_OPTION_SELECTED = "item_option_selected",
  CUSTOM_ITEM_CREATED = "custom_item_created",
  CUSTOM_ITEM_DIFFERENT = "custom_item_different",
  CUSTOM_ITEM_DISMISSED = "custom_item_dismissed",
  EXISTING_CUSTOM_ITEM_DISMISSED = "existing_custom_item_dismissed",
  // SET_CHECKOUT_OPTION = "set_checkout_option",
  // CHECKOUT_PROGRESS = "checkout_progress",
  REFUND = "refund",
  ADD_PAYMENT_INFO = "add_payment_info",
  PAYMENT_SUCCESS = "payment_success",
  PAYMENT_FAILURE = "payment_failure",
  // TODO: find where the changes are done????
  // Pending payment IRCTC-452 to be implemented with this
  PAYMENT_PENDING = "payment_pending",
  PURCHASE = "purchase",
  ITEM_PURCHASE = "item_purchase",
  CALL_DELIVERY_AGENT = "call_delivery_agent",
  PANTRY_BACK_HOME_TAPPED = "pantry_back_home_tapped",
  PANTRY_NEXT_ORDER_TAPPED = "pantry_next_order_tapped",
  PANTRY_PLACE_ORDER_TAPPED = "pantry_place_order_tapped",
  PANTRY_PROCEEDED_TAPPED = "pantry_proceeded_tapped",
  PANTRY_CART_CLEAR_TAPPED = "pantry_cart_clear_tapped",
  PANTRY_PREVIOUS_MENU_TAPPED = "pantry_previous_menu_tapped",
  PANTRY_ORDER_SUMMARY_TAPPED = "pantry_order_summary_tapped",
  // TODO: NEW to add
  // SEND_ADVANCED_VOUCHER
  // SEND_PAYMENT_VOUCHER
  // SEND_CREDIT_VOUCHER

  CVC_PLEDGE_CLICKED = "cvc_pledge_clicked",
  MENU_SEARCH_TAP = "menu_search_tap",
  PANTRY_MODAL_SHOWN = "pantry_modal_shown",
  PANTRY_OPTION_TAPPED = "pantry_option_tapped",
  PANTRY_ECAT_OPTION_TAPPED = "pantry_ecat_option_tapped",
  PANTRY_MENU_VIEWED = "pantry_menu_viewed",
  PANTRY_MENU_CATEGORY_EXPANDED = "pantry_menu_category_expanded",
  PANTRY_ADD_TO_CART = "pantry_add_to_cart",
  PANTRY_REMOVE_TO_CART = "pantry_remove_to_cart",
  PANTRY_SCHEDULE_EXPANDED = "pantry_schedule_expanded",
  PANTRY_SCHEDULE_SELECTED = "pantry_schedule_selected",
  PANTRY_ORDER_PLACED = "pantry_order_placed",
  PANTRY_ITEM_PURCHASE = "pantry_item_purchase",
  FILTER_REMOVED = "filter_removed",
  FILTER_APPLIED = "filter_applied",
}

export enum EventProperty {
  APP_NAME = "app_name",
  CONTENT_TYPE = "content_type",
  ITEM_CATEGORY = "item_category",
  ITEM_ID = "item_id",
  ITEM_NAME = "item_name",
  GROUP_ID = "group_id",
  LOCATION = "location",
  CHECKOUT_OPTION = "checkout_option",
  CURRENCY = "currency",
  VALUE = "value",
  ORDER_ITEMS = "order_items",
  ITEAM_NAME = "item_name",
  SELLING_PRICE = "selling_Price",
  SEARCH_TERM = "search_term",
  TRAIN_NUMBER = "train_number",
  MENU_CATEGORY = "menu_category",
  PRODUCT_NAME = "product_name",
  IS_VEG = "is_veg",
  BASE_PRICE = "base_price",
  SCHEDULE_SLOT = "schedule_slot",
  ORDER_ID = "order_id",
  TOTAL = "total",
  AMOUNT_PAYABLE = "amount_payable",
  POD_SELECTED = "pod_selected",
  ETA = "eta",
  PAY_BY = "pay_by",
  FILTER = "filter",
  SCREEN = "screen",
  APPLIED_FILTERS = "applied_filters",
}
