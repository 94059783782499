declare global {
  interface Window {
    eCatering?: any;
  }
}

const k = (key: string) => `eCatering.${key}`;
const getStorage = (storageClass: Storage | undefined) => ({
  get: (key: string) => {
    if (storageClass && storageClass.getItem && key) {
      let valueInStorage = storageClass.getItem(k(key));
      if (!valueInStorage || valueInStorage === "undefined") {
        return null;
      }
      const item = JSON.parse(valueInStorage);
      if (Date.now() > item.expiry) {
        storageClass.removeItem(k(key));
        return null;
      }
      return item.value;
    } else if (typeof window !== "undefined") {
      const item = window?.eCatering?.Storage && JSON.parse(window.eCatering.Storage[k(key)] || "{}");
      if (Date.now() > item?.expiry) {
        delete window.eCatering.Storage[k(key)];
        return null;
      }
      return item?.value || null;
    } else {
      return null;
    }
  },
  set: (key: string, value: any, ttl = 86400) => {
    const item = {
      value,
      ttl,
      expiry: Date.now() + ttl * 1000,
    };
    if (storageClass && storageClass.getItem) {
      storageClass.setItem(k(key), JSON.stringify(item));
    } else if (typeof window !== "undefined") {
      window.eCatering = window.eCatering || {};
      window.eCatering.Storage = window.eCatering.Storage || {};
      window.eCatering.Storage[k(key)] = item;
    }
  },
  del: (key: string) => {
    if (storageClass && storageClass.getItem) {
      storageClass.removeItem(k(key));
    } else if (typeof window !== "undefined") {
      window.eCatering = window.eCatering || {};
      window.eCatering.Storage = window.eCatering.Storage || {};
      delete window.eCatering.Storage[k(key)];
    }
  },
  clearAll: () => {
    if (storageClass) {
      storageClass.clear();
    }
  },
});

export const Storage = getStorage(typeof window !== "undefined" ? window.sessionStorage : undefined);
export const PersistantStorage = getStorage(typeof window !== "undefined" ? window.localStorage : undefined);
