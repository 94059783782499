import React, { createContext, useMemo, useReducer } from "react";
import { getInitialPnrReducerState, pnrContextReducer } from "./pnrContextReducer";
import { PnrContextType } from "./types";

const PnrContext = createContext<PnrContextType>({
  ...getInitialPnrReducerState(),
  setPnrInfo: undefined,
  setStationInfo: undefined,
  setOutletInfo: undefined,
  loadState: undefined,
  resetState: undefined,
});

const PnrContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [pnrContextState, dispatch] = useReducer(pnrContextReducer, getInitialPnrReducerState());

  const memoizedPnrInfo: PnrContextType = useMemo(
    () => ({
      ...pnrContextState,
      setPnrInfo: ({ pnr, pnrResponse }) => dispatch({ type: "SET_CURRENT_PNR_INFO", payload: { pnr, pnrResponse } }),
      setStationInfo: (stationInfo) => dispatch({ type: "SET_CURRENT_STATION", payload: stationInfo }),
      setOutletInfo: (outletInfo) => dispatch({ type: "SET_CURRENT_OUTLET", payload: outletInfo }),
      loadState: (payload) => dispatch({ type: "LOAD_STATE", payload }),
      resetState: (payload) => dispatch({ type: "RESET_STATE", payload }),
    }),
    [pnrContextState]
  );

  return <PnrContext.Provider value={memoizedPnrInfo}>{children}</PnrContext.Provider>;
};

export { PnrContextProvider, PnrContext };
