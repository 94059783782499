import { ParsedUrlQuery } from "querystring";
import { useEffect } from "react";
import { SetAffiliateParams } from "../services/utils/Common";
import { Config } from "../services/utils/Config";

declare global {
  interface Window {
    IRCTC: any;
    openLoader: any;
    closeLoader: any;
  }
}

const useInitApp = (routeQuery?: ParsedUrlQuery) => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      console.log("Config", Config);
      window.IRCTC = {
        api: Config.api,
      };
      let loading = document.createElement("div");
      loading.id = "loading";
      let spinner = document.createElement("div");
      spinner.className = "lds-dual-ring";
      spinner.appendChild(document.createElement("div"));
      loading.appendChild(spinner);
      window.openLoader = () => {
        if (!document.getElementById("loading")) {
          document.body.appendChild(loading);
        }
      };
      window.closeLoader = () => {
        if (document.getElementById("loading")) {
          document.body.removeChild(loading);
        }
      };
      // console.log("setting default captcha flag");
      window.loadingGrecaptcha = window.loadingGrecaptcha ?? true;
      SetAffiliateParams(routeQuery);
    }
  }, []);
};

export default useInitApp;
