import { CommonContextReducerState, CurrentPnrInfo, CurrentStationInfo, PnrContextReducerState, SetCurrentStationInfo } from "@context/index";
import { ElementId, OrderStatusState, StorageRef } from "@enums/common";
import { ErrorMessages } from "@enums/messages";
import { PnrResponse, SelectedQueryInfo, User, UserResponse } from "@interfaces/index";
import { OrderInfo, SendFeedbackData } from "@interfaces/orders";
import Analytics from "@services/analytics";
import lowerCase from "lodash/lowerCase";
import reverse from "lodash/reverse";
import startCase from "lodash/startCase";
import { NextRouter } from "next/router";
import { ParsedUrlQuery } from "querystring";
import { dateTimeFormatter } from "./DateTimeFormatter";
import { CaptchaGet, CaptchaPost, Post } from "./Request";
import { PersistantStorage, Storage } from "./Storage";

export const isStageOrDev = () => window.location.host.includes("stage") || window.location.host.includes("dev");

export const getPrice = (price: number) => `₹${price && price % 1 != 0 ? price.toFixed(2) : price}`;

const getBrowserInfo = () => {
  const info = {
    ios: false,
    iosWebview: false,
    // @ts-ignore
    isFirefox: typeof InstallTrigger !== "undefined",
    androidWebview: false,
    phonepeEnv: false,
    ucBrowser: false,
    mobileCheck: (soft?: boolean) => true,
    newTabSupported: () => false,
    mobileCheckSoft: true,
    mobileCheckHard: true,
  };
  if (typeof window !== "undefined") {
    // TODO: enable following after replacing mobileCheck function in code
    // const browserInfo = PersistantStorage.get(StorageRef.BrowserInfo);
    // if (browserInfo) {
    //   return browserInfo as typeof info;
    // }

    // @ts-ignore
    const standalone = window.navigator.standalone,
      userAgent = window.navigator.userAgent.toLowerCase(),
      safari = /safari/.test(userAgent),
      ios = /iphone|ipod|ipad/.test(userAgent);
    info.ios = ios;
    info.iosWebview = ios && !standalone && !safari;
    info.androidWebview = /\swv\).+(chrome)\/([\w\.]+)/i.test(userAgent);
    info.phonepeEnv = /phonepe/.test(userAgent);
    info.ucBrowser = /((?:[\s\/])uc?\s?browser|(?:juc.+)ucweb)[\/\s]?([\w\.]+)/i.test(userAgent);
    info.newTabSupported = () => !(info.androidWebview || info.iosWebview || info.ucBrowser);
    info.mobileCheck = (soft = true) => {
      let check = false;
      ((a) => {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            a.substr(0, 4)
          )
        )
          check = true;
      })(
        navigator.userAgent ||
          navigator.vendor ||
          // @ts-ignore
          window.opera
      );
      return check || (soft && window.document.body.clientWidth <= 768);
    };
    info.mobileCheckSoft = info.mobileCheck();
    info.mobileCheckHard = info.mobileCheck(true);
    // PersistantStorage.set("browserInfo", info);
  }
  // console.log("BrowserInfo", info);
  return info;
};

export const BrowserInfo = getBrowserInfo();

export const nameRegex = /^[a-zA-Z ]+$/;
export const phoneRegex = /^[6-9]\d{9}$/;
export const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const UtmSources = {
  IPSATOR: "IPSATOR",
  IRCTC: "IRCTC",
};
const InternalUtmSources = Object.values(UtmSources);

type AffiliateInfoType = {
  affiliateId?: string;
  source?: string;
  medium?: string | null;
};
const allowedMedium = ["ANDROID_APP", "MOBILE_WEB", "DESKTOP_WEB", "IOS_APP", "PROMO_EMAIL", "PROMO_SMS", "PROMO_WA", "PROMO_WA_UI"];
const getAffiliateMedium = (medium: string | null) =>
  medium && allowedMedium.filter((medium) => !medium.startsWith("PROMO_")).includes(medium) ? medium : null;
export const SetAffiliateParams = (routeQuery?: ParsedUrlQuery) => {
  // console.log("fetching affiliate params", routeQuery);
  if (typeof window !== "undefined") {
    const params = new URLSearchParams(window.location.search);
    const utmSource = (routeQuery?.utm_source || params.get("source") || params.get("utm_source") || "") as string;
    if (utmSource) {
      let AffiliateInfo: AffiliateInfoType = {};
      const medium = ((routeQuery?.utm_medium || params.get("medium") || params.get("utm_medium") || "") as string)?.toUpperCase();
      // const campaign = params.get("utm_campaign");
      if (utmSource.includes("_AFFILIATE")) {
        // Affiliate Info needs to be refactored to avoid confusion and decision pending on campaign
        AffiliateInfo = {
          affiliateId: utmSource,
          source: utmSource.split("_")[0],
          medium: getAffiliateMedium(medium),
        };
      } else if (utmSource.toLowerCase() === "irctc") {
        AffiliateInfo = {
          affiliateId: UtmSources.IRCTC,
          source: UtmSources.IRCTC,
          medium: getAffiliateMedium(medium),
        };
      } else if (utmSource.toLowerCase() === "ir-ctc") {
        AffiliateInfo = {
          affiliateId: UtmSources.IPSATOR,
          source: UtmSources.IPSATOR,
          medium: "PROMO_SMS",
        };
      } else if (utmSource.toLowerCase() === "ipsator") {
        AffiliateInfo = {
          affiliateId: UtmSources.IPSATOR,
          source: UtmSources.IPSATOR,
          medium: medium && allowedMedium.filter((medium) => medium.startsWith("PROMO_")).includes(medium) ? medium : "",
        };
      }
      if (AffiliateInfo && AffiliateInfo.medium && !allowedMedium.includes(AffiliateInfo.medium)) {
        AffiliateInfo.medium = "NA";
      }
      if (Object.keys(AffiliateInfo).length > 0) {
        Storage.set("AffiliateInfo", AffiliateInfo);
      }
      // console.log("AffiliateInfo", AffiliateInfo);
    }
  }
};

const MOBILE_WEB = "mobile web";
const DESKTOP_WEB = "desktop web";

export const GetOrderFrom = () => {
  if (typeof window !== "undefined") {
    const AffiliateInfo = Storage.get("AffiliateInfo");
    if (BrowserInfo.phonepeEnv) {
      if (AffiliateInfo && AffiliateInfo.affiliateId && AffiliateInfo.affiliateId === "CONFIRMTKT_AFFILIATE") {
        Storage.set("AffiliateInfo", { ...AffiliateInfo, medium: "B2B" });
        return "CONFIRMTKT";
      }
      return "phonepeApp";
    }
    if (AffiliateInfo && AffiliateInfo.source && AffiliateInfo.source !== UtmSources.IPSATOR) {
      return AffiliateInfo.source;
    }
    if (BrowserInfo.iosWebview) {
      return "ios app";
    }
    return BrowserInfo.mobileCheck(false) ? MOBILE_WEB : DESKTOP_WEB;
  }
  return MOBILE_WEB;
};

export const GetAppNameFromBrowserInfo = () => {
  if (typeof window !== "undefined") {
    return BrowserInfo.mobileCheck(false) ? MOBILE_WEB : DESKTOP_WEB;
  }
  return MOBILE_WEB;
};

export const getAffiliateData = () => {
  let affiliateData: any = {};
  const AffiliateInfo = Storage.get("AffiliateInfo");
  if (AffiliateInfo && AffiliateInfo.source) {
    if (!InternalUtmSources.includes(AffiliateInfo.affiliateId)) {
      affiliateData.affiliateId = AffiliateInfo.affiliateId;
    }
    affiliateData.orderSource = AffiliateInfo.medium;
  }
  return affiliateData;
};

export const clearAffiliateData = () => {
  Storage.del("AffiliateInfo");
};

export const clearCurrentOrderInfoInStorage = () => Storage.del(StorageRef.CurrentOrderInfo);
export const clearQuerySelectedTrainInfoInStorage = () => Storage.del(StorageRef.SelectedTrainInfoForQuery);

export const loadContextFromStorage = (state: CommonContextReducerState) => {
  PersistantStorage.set(StorageRef.CommonContext, state);
};

export const storeContextInStorage = (pnrContextState: PnrContextReducerState, commonState: CommonContextReducerState) => {
  Storage.set(StorageRef.PnrContext, pnrContextState);
  PersistantStorage.set(StorageRef.CommonContext, commonState);
};

export const getCommonContextFromStorage = (): CommonContextReducerState | undefined => {
  return PersistantStorage.get(StorageRef.CommonContext);
};

export const getContextFromStorage = () => {
  const commonContext = getCommonContextFromStorage();
  const pnrContext = Storage.get(StorageRef.PnrContext) as undefined | PnrContextReducerState;
  return { commonContext, pnrContext };
};

export const getUserInfoFromStorage = () => {
  const contextState = getCommonContextFromStorage();
  return contextState?.userInfo ?? undefined;
};

export const getCurrentOrderInfo = () => {
  return Storage.get(StorageRef.CurrentOrderInfo) as OrderInfo | undefined;
};

export const getQueryTrainInfoFromStorage = () => {
  return Storage.get(StorageRef.SelectedTrainInfoForQuery) as SelectedQueryInfo | undefined;
};

export const setQueryTrainInfoInStorage = (
  trainInfo: SelectedQueryInfo["trainInfo"] | undefined,
  boardingDate: string,
  boardingStationInfo: { code: string; name?: string }
) => {
  const selectedTrainInfo: SelectedQueryInfo = {
    trainInfo,
    boardingDate,
    boardingStation: boardingStationInfo.code,
    boardingStationName: boardingStationInfo.name,
  };
  Storage.set(StorageRef.SelectedTrainInfoForQuery, selectedTrainInfo);
};

export const getQueryTrainSearchString = () => Storage.get(StorageRef.QueryStringForTrainSearch) as string | undefined;
export const clearQueryTrainSearchString = () => Storage.del(StorageRef.QueryStringForTrainSearch) as string | undefined;
export const setTrainSearchStationsArray = (stations: Array<object>) =>
  Storage.set(StorageRef.TrainSearchStationsArray, stations) as string | undefined;

export const LoginUser = (
  mobile: string,
  password: string,
  { successCB, failureCB }: { setUser?: any; successCB?: (arg0: User) => void; failureCB?: Function } = { setUser: () => {} }
) => {
  if (!(phoneRegex.test(mobile.trim()) && emailRegex.test(password.trim()))) {
    return new Promise(() => {
      if (failureCB) {
        failureCB(true);
      }
    });
  }
  return CaptchaPost({ url: "/api/v2/auth/customer/login", data: { mobile, password }, isAuth: true }).then((res: UserResponse) => {
    if (res.status === "success" && res.result) {
      const user = res.result;
      //try to make a common function to encapsulate all the user attributes in one single file
      Analytics.moengageAttribute()?.login(user.id.toString());
      Analytics.moengageAttribute()?.fullName(user.fullName);
      if (successCB) {
        successCB(user);
      }
    } else if (failureCB) {
      failureCB(true);
    }
  });
};

export const SignupUser = (
  fullname: string,
  mobile: string,
  password: string,
  { successCB, failureCB }: { successCB?: (user: User) => void; failureCB?: () => void } = {}
) => {
  if (!(phoneRegex.test(mobile.trim()) && emailRegex.test(password.trim()) && nameRegex.test(fullname.trim()))) {
    if (failureCB) {
      failureCB();
    }
  } else {
    CaptchaPost({
      url: "/api/v2/customer",
      data: { createdFrom: GetOrderFrom(), email: password, fullName: fullname, mobile: mobile },
      isAuth: true,
    })
      .then((res: UserResponse) => {
        if (res.status === "success" && res.result) {
          Analytics.moengageAttribute()?.login(res.result.id.toString());
          Analytics.moengageAttribute()?.fullName(res.result.fullName);
          successCB && successCB(res.result);
        } else if (failureCB) {
          failureCB();
        }
      })
      .catch(() => {
        failureCB && failureCB();
      });
  }
};

export const getTimeLongMonthFormattedTime = (timeString: string) => {
  const timeObj = dateTimeFormatter(timeString);
  return `${timeObj.timeString}, ${timeObj.longDateString}`;
};

export const orderStatusValidator = (
  orderStatus: OrderStatusState,
  type: "inclusive" | "exclusive",
  ...orderStatusToValidate: OrderStatusState[]
) => {
  if (type === "inclusive") {
    return !!orderStatusToValidate.find((status) => status === orderStatus);
  } else {
    return !orderStatusToValidate.find((status) => status === orderStatus);
  }
  return false;
};

export const joinNamesArray = (arr: Record<string, any>[] | string[], nameKey: string | null) => {
  let finalNames = "";
  let arrNames: string[] = [];
  if (arr.length > 0) {
    if (typeof arr[0] === "string" && nameKey === null) {
      arrNames = (arr as string[]).map((item) => item.trim());
    } else if (!!nameKey && typeof arr[0] === "object" && typeof arr[0][nameKey] === "string") {
      arrNames = (arr as Record<string, any>[]).map((item) => item[nameKey].trim());
    }
  }
  finalNames = arrNames.slice(0, 2).join(", ");

  if (arr.length > 2) {
    finalNames += ` & ${arr.length - 2} more`;
  }
  return finalNames;
};

export const getCuisinesList = (cuisines?: string[] | null) => cuisines?.map((cuisine) => startCase(lowerCase(cuisine))).join(", ") || "";

export const sendFeedbackCall = (data: SendFeedbackData) =>
  new Promise<void>((resolve, reject) => {
    Post({ url: "/api/v2/feedback", data, isAuth: true }).then((res) => {
      resolve();
    });
  });

export const getTimeAndDateForOutletApiCall = (dateTime: string) => {
  const { timeString, dateStringNumeric } = dateTimeFormatter(dateTime, "2-digit", 24);
  return { time: timeString, date: dateStringNumeric };
};

export type PnrSearchHistory = (PnrResponse["result"] & {
  pnr: string;
})[];

export const getPnrSearchHistoryFromStorage = (): PnrSearchHistory => {
  const pnrSearchHistory = PersistantStorage.get(StorageRef.PNRSearchHistory);
  return pnrSearchHistory || [];
};

export const addPnrToSearchHistory = (pnrInfo: PnrSearchHistory[0]) => {
  let currentPnrSearchHistory = getPnrSearchHistoryFromStorage();
  if (currentPnrSearchHistory.length) {
    currentPnrSearchHistory = currentPnrSearchHistory.filter((pnrSearchInfo) => pnrSearchInfo.pnr !== pnrInfo.pnr);
  }
  currentPnrSearchHistory.unshift(pnrInfo);
  PersistantStorage.set(StorageRef.PNRSearchHistory, currentPnrSearchHistory);
};

export type StationInfoForHomePage = {
  [x: string]: any;
  info: {
    title?: undefined;
    type?: undefined;
    description?: undefined;
    outlets?: undefined;
    name: string;
    stationName: string;
    code?: undefined;
  };
};

export const showOrderOtp = (orderStatus: OrderStatusState) =>
  orderStatusValidator(
    orderStatus,
    "exclusive",
    OrderStatusState.ORDER_CANCELLED,
    OrderStatusState.ORDER_UNDELIVERED,
    OrderStatusState.ORDER_DELIVERED,
    OrderStatusState.ORDER_PARTIALLY_DELIVERED,
    OrderStatusState.ORDER_PENDING
  );

export const clearCartState = () => {
  Storage.del(StorageRef.CartState);
  PersistantStorage.del(StorageRef.CouponRemovalInfo);
};

export const deleteRedirectHomeInfo = () => {
  Storage.del(StorageRef.redirectToHome);
};

export const hasScrollTopReached = (element: HTMLElement) => {
  return element.scrollTop === 0;
};

export const hasScrollBottomReached = (element: HTMLElement, buffer = 0) => {
  const { scrollTop, clientHeight, scrollHeight } = element;
  return scrollTop + clientHeight >= scrollHeight - buffer;
};

export const getPageContainer = () => document.getElementById(ElementId.pageContainer);

export const reverseDate = (boardingDate: string) => reverse((boardingDate as string).split("-")).join("-");

export const getPnrInfo = (pnr: string) =>
  new Promise(async (resolve: (val: PnrResponse) => void, reject: (val: string) => void) => {
    return CaptchaGet({ url: `/api/v2/pnr/stations/token?pnr=${pnr}`, mandatoryCaptchaToken: true }).then((data) => {
      if (data) {
        resolve(data as PnrResponse);
      } else {
        reject(ErrorMessages.TRY_AGAIN);
      }
    });
  });

export const getHistoryStack = () => Storage.get(StorageRef.HistoryStack) as string[] | undefined;

export const addUrlToHistoryStack = (url: string) => {
  const history = getHistoryStack() || [];
  history.unshift(url);
  Storage.set(StorageRef.HistoryStack, history);
};

export const isCurrentRouteFirstPageInHistory = (router: NextRouter) => {
  const historyStack = getHistoryStack();
  return historyStack && historyStack[historyStack.length - 1] === router.asPath;
};

export const handleGoBackNav = (router: NextRouter) => {
  // if the current route is the first page user opened, goback should take the user to home page
  const { back, push } = router;
  if (isCurrentRouteFirstPageInHistory(router)) {
    push("/");
  } else {
    back();
  }
};

export const initializeHistoryStack = (url: string) => {
  const sessionHistory = getHistoryStack();
  const history = sessionHistory || [];
  history.unshift(url);
  Storage.set(StorageRef.HistoryStack, history);
};

export const getOutletOrderByTimeInfo = (orderByTime: string) => {
  const orderByTimeInfo = dateTimeFormatter(orderByTime);
  return {
    orderByTimeString: orderByTimeInfo.timeString,
    orderByDateString: orderByTimeInfo.dateString,
    orderIn24Hours: orderByTimeInfo.timeDiffInHours < 24,
  };
};

export const removeFunctionsFromObj = (obj: Record<string, any>): Record<string, any> => {
  const _newObj: Record<string, any> = {};
  Object.keys(obj).forEach((key) => {
    const value = obj[key];
    if (typeof value !== "function") {
      _newObj[key] = value;
    }
  });
  return _newObj;
};

export const getCurrentPnrInfoFromPnrResponse = (pnr: string, pnrResponse: PnrResponse["result"]): CurrentPnrInfo => {
  const pnrInfo: CurrentPnrInfo = {
    stations: pnrResponse.stations,
    trainInfo: pnrResponse.trainInfo,
    seatInfo: pnrResponse.seatInfo,
    boardingStationInfo: pnrResponse.stations.find((station) => station.code === pnrResponse.trainInfo.boarding) || null,
    isValidPnr: true,
    pnr,
  };
  return pnrInfo;
};

export const setStationArrivalTimeInfo = (station: Parameters<SetCurrentStationInfo>[0]): CurrentStationInfo => {
  const stationInfo = { ...station } as CurrentStationInfo;
  if (stationInfo.arrival) {
    const arrivalTimeInfo = dateTimeFormatter(stationInfo.arrival);
    stationInfo.arrivalDateString = arrivalTimeInfo.dateString;
    stationInfo.arrivalTimeString = arrivalTimeInfo.timeString;
  }
  return stationInfo;
};
