import { useContext } from "react";
import { CommonContext } from "../context";

const useCommonContext = () => {
  const commonContextInfo = useContext(CommonContext);

  return commonContextInfo;
};

export default useCommonContext;
