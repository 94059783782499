import { add_remove_from_cart, item_purchase, menu_category_expanded, order_placed, outlet_selected, station_selected } from "./types";

export type MoEngageTracker = {
  EVENT?: any;
  ATTR?: any;
};

const moengage = () => {
  let Moengage: any;
  if (typeof window !== "undefined") {
    Moengage = window.Moengage;
  }

  function trackMoengageUser() {
    return {
      login: (userId: string) => {
        Moengage && Moengage.add_unique_user_id(userId);
      },
      logout: () => {
        Moengage && Moengage.destroy_session();
      },
      firstName: (name: string) => {
        Moengage && Moengage.add_first_name(name);
      },
      lastName: (name: string) => {
        Moengage && Moengage.add_last_name(name);
      },
      fullName: (name: string) => {
        Moengage && Moengage.add_user_name(name);
      },
      addAttribute: (key: string, value: string | Date) => {
        Moengage && Moengage.add_user_attribute(key, value);
      },
    };
  }

  function trackMoEngageEvent(eventName: string, eventPayload?: any) {
    if (typeof window !== "undefined") {
      window.Moengage &&
        window.Moengage.track_event(eventName, {
          ...eventPayload,
        });
    }
  }

  const EVENT = {
    stations_viewed: () => trackMoEngageEvent("stations_viewed"),
    station_selected: (data: station_selected) => trackMoEngageEvent("station_selected", data),
    outlet_selected: (data: outlet_selected) => trackMoEngageEvent("outlet_selected", data),
    menu_category_expanded: (data: menu_category_expanded) => trackMoEngageEvent("menu_category_expanded", data),
    add_to_cart: (data: add_remove_from_cart) => trackMoEngageEvent("add_to_cart", data),
    remove_from_cart: (data: add_remove_from_cart) => trackMoEngageEvent("remove_from_cart", data),
    sign_up: () => trackMoEngageEvent("sign_up"),
    coupon_failed: () => trackMoEngageEvent("coupon_failed"),
    coupon_applied: () => trackMoEngageEvent("coupon_applied"),
    item_purchase: (data: item_purchase) => trackMoEngageEvent("item_purchase", data),
    order_placed: (data: order_placed) => trackMoEngageEvent("order_placed", data),
  };

  const ATTR = {
    login: (data: string) => trackMoengageUser().login(data),
    logout: () => trackMoengageUser().logout(),
    fullName: (data: string) => trackMoengageUser().fullName(data),
    last_searched_pnr: (data: string) => trackMoengageUser().addAttribute("last_searched_pnr", data),
    last_searched_pnr_final_eta: (data: Date) => trackMoengageUser().addAttribute("last_searched_pnr_final_eta", data),
    last_selected_station_code: (data: string) => trackMoengageUser().addAttribute("last_selected_station_code", data),
    last_selected_station: (data: string) => trackMoengageUser().addAttribute("last_selected_station", data),
    last_selected_vendor_id: (data: string) => trackMoengageUser().addAttribute("last_selected_vendor_id", data),
    last_selected_outlet: (data: string) => trackMoengageUser().addAttribute("last_selected_outlet", data),
    last_selected_outlet_id: (data: string) => trackMoengageUser().addAttribute("last_selected_outlet_id", data),
    last_order_id: (data: string) => trackMoengageUser().addAttribute("last_order_id", data),
    last_selected_outlet_station_code: (data: string) => trackMoengageUser().addAttribute("last_selected_outlet_station_code", data),
    last_selected_outlet_order_before: (data: Date) => trackMoengageUser().addAttribute("last_selected_outlet_order_before", data),
    last_selected_outlet_order_before_hhmm: (data: string) => trackMoengageUser().addAttribute("last_selected_outlet_order_before_hhmm", data),
  };

  if (typeof window !== "undefined" && window.Moengage) {
    return { EVENT, ATTR };
  }

  return {};
};

export default moengage;
