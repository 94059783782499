export const ErrorMessages = {
  INVALID_PNR: "Please enter valid pnr.",
  TRY_AGAIN: "We're sorry but something went wrong. Please try again.",
  CART_VALUE_INVALID: "Subtotal must be ₹100 or above to apply this coupon!",
  ORDER_AMOUNT_INVALID: "Order amount must be greater than coupon value.",
  PREPAYMENT_NECESSARY: "Prepayment necessary for order value more than ₹1000 to confirm. Please pay on next page.",
  INVALID_COUPON_CODE: "Nope, that didn't work.",
  ENTER_COUPON_CODE: "Please enter coupon code",
  ERROR_OCCURED: "Error occured. Please try again.",
  FEEDBACK_MESSAGE_EMPTY: "Please provide comment",
  ERROR_SUBMITTING_FEEDBACK: "Error submitting feedback. Please try again",
  SPECIAL_CHAR_REMOVAL: "Please remove special characters",
  ENTER_FULL_NAME: "Please enter your full name",
  ONLY_ALPHABET: "Only alphabets and spaces are allowed",
  APPLY_COUPON: "Please apply the coupon",
  ENTER_VALID_NAME: "Please enter valid name.",
  ENTER_VALID_MOBILE_NUMBER: "Please enter valid mobile number.",
  ENTER_VALID_EMAIL_ID: "Please enter valid email id.",
  FEEDBACK_OPTIONS_EMPTY: "Please tell us what went wrong this will help us to improve our service.",
  FEEDBACK_ABOVE_THREE_STARS: "Tell us what did you like about our service.",
  PAYMENT_AFTER_CUTOFF:
    "Can't pay now. Order is already dispatched to restaurant. If amount has been deducted it will be refunded in upto 15 working days.",
  PAYMENT_ERROR: "Your payment for the order has failed. If your account was debited, the amount will be refunded in up to 15 working days.",
  PAYMENT_ALREADY_INIATED: "Can't pay now. Payment already initiated",
  SAME_ALTERNATE_PRIMARY_NO: "Alternate number should not be same as your primary number.",
  NO_STATIONS_FOUND: "Sorry, we're unable to find any restaurant to serve you.",
};

export const InstructionMessages = {
  ABOVE_THOUSAND: "Prepayment necessary for order above ₹1000.",
  THANKYOU_MESSAGE_FEEDBACK: "We're glad you liked the service.Looking forward to serving you again soon!",
  THANKYOU_MESSAGE_COMPLAINT: "We have submitted your feedback to concerned IRCTC zone.They will get back to you shortly.",
  FEEDBACK_SORRY_MESSAGE: "We're really sorry about that.",
  FEEDBACK_SUBMIT_ASSIST: "Please, submit your feeback so that we may assist you.",
  FEEDBACK_ASK_IF_ALL_ITEMS_RECEIVED: "Did you recieve all the items you ordered ?",
  HELP_YOUR_CATERER: "Help your caterer improve their service by rating them.",
  ORDER_DELIVERED: "Have you recieved your order ?",
  PREPAYMENT: "Prepayment necessary for order value more than ₹1000 to confirm",
  CONFIRM_CANCELATION: "Do you really want to cancel this order?",
  ORDER_CANCELED_PREPAID: "Order has been cancelled successfully and refund has been initiated.",
  ORDER_CANCELED_COD: "Order has been cancelled successfully.",
  PAYMENT_FAILED: "Payment failed. If the amount has been deducted from your account, it will be refunded in upto 15 days.",
  REFUND_MESSAGE: "Refunds may take upto 10 working days to reflect in your account.",
  PENDING_ORDER_PAYMENT: "Pay to confirm the order.",
  INVOICE_EMAIL: "Email Invoice",
  NO_OUTLET_AVAILABLE: "No [stationCode] currently available for this station.",
  ECATERING_NOT_AVALAIBLE: "e-Catering not available for this journey.",
  FEEDBACK_PERSON_COUNT_LIMIT:
    "Sorry, bulk requests are only meant for 10 or more passengers.For lesser passengers, press back & directly place order using your PNR on the homepage.",
  PENDING_ORDER_PAYMENT_PHONEPE: "Pay using PhonePe app to confirm the order",
  FEEDBACK_PERSON_COUNT_UPPER_LIMIT: "Sorry, we can't serve more than 2000 passengers.",
  INALID_CART: "Following items are unavailable please remove them from cart.",
};
