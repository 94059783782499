import Script from "next/script";
import { useEffect, useState } from "react";

const RecaptchaScript = ({ Config, newsrc, newOnLoad }: { pageProps: any; Config: any; newsrc?: any; newOnLoad?: any }) => {
  const [delayTimer, setDelayTimer] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => setDelayTimer(false), 5000);
    return () => {
      clearInterval(timer);
    };
  }, []);
  const onLoad = () => {
    window.grecaptcha.ready(function () {
      console.log("captcha loaded");
      window.loadingGrecaptcha = false;
    });
  };
  return delayTimer ? (
    <></>
  ) : (
    <Script
      id="recaptcha-lib"
      strategy="afterInteractive"
      src={newsrc ? newsrc : "https://www.google.com/recaptcha/api.js?render=" + Config.gToken}
      onLoad={newOnLoad ? newOnLoad : onLoad}
    />
  );
};
export default RecaptchaScript;
