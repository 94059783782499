export enum Common {}

export enum Color {
  ACTIVE = "#f26122",
  INACTIVE = "#707070",
}

export enum StatusColorCodes {
  DELIVERED = "#00ff00",
  ORDER_CONFIRMED = "#9d2424",
}

export enum StorageRef {
  BrowserInfo = "browserInfo",
  CartState = "cartState",
  PNRSearchHistory = "search_history",
  PnrInfo = "pnrInfo",
  PnrContext = "PnrContext",
  CommonContext = "CommonContext",
  CurrentPnr = "currentPnr",
  CurrentOrderInfo = "currentOrderInfo",
  CouponRemovalInfo = "couponRemovalInfo",
  redirectToHome = "redirectToHome",
  showOrderPlacedBanner = "showOrderPlacedBanner",
  initiateNewOrderPayment = "initiateNewOrderPayment",
  Auth = "auth",
  cartNavUsingBottomNav = "cartNavUsingBottomNav",
  paytmPaymentInProgress = "paytmPaymentInProgress",
  ShowTrainSearchQuery = "showTrainSearchQuery",
  SelectedTrainInfoForQuery = "selectedTrainInfoForQuery",
  QueryStringForTrainSearch = "queryStringForTrainSearch",
  HistoryStack = "historyStack",
  ValidateCart = "validateCart",
  TrainSearchStationsArray = "trainSearchStationsArray",
}

export enum StorageType {
  LOCAL = "local",
  SESSION = "session",
}

export enum StorageAction {
  GET = "get",
  SET = "set",
  DELETE = "del",
  APPEND = "append",
}

export enum APIAction {
  VALIDATE = "validate",
  FETCH = "fetch",
}

export enum APIStatusResponse {
  FAILURE = "failure",
  SUCCESS = "success",
}

export enum PaymentMethods {
  razorpay = "razorpay",
  paytm = "paytm",
  cod = "cod",
  phonePe = "phonePe",
}

export enum OrderStatusState {
  "ORDER_CONFIRMED" = "ORDER_CONFIRMED",
  "ORDER_PLACED" = "ORDER_PLACED",
  "ORDER_DELIVERED" = "ORDER_DELIVERED",
  "ORDER_PARTIALLY_DELIVERED" = "ORDER_PARTIALLY_DELIVERED",
  "ORDER_CANCELLED" = "ORDER_CANCELLED",
  "ORDER_UNDELIVERED" = "ORDER_UNDELIVERED",
  "ORDER_PREPARING" = "ORDER_PREPARING",
  "ORDER_PREPARED" = "ORDER_PREPARED",
  "ORDER_OUT_FOR_DELIVERY" = "ORDER_OUT_FOR_DELIVERY",
  "ORDER_PENDING" = "ORDER_PENDING",
  "PICKUP_BOY_ALLOCATED" = "PICKUP_BOY_ALLOCATED",
  "OUT_FOR_PICKUP" = "OUT_FOR_PICKUP",
  "ORDER_PICKED_UP" = "ORDER_PICKED_UP",
  "DELIVERY_BOY_ALLOCATED" = "DELIVERY_BOY_ALLOCATED",
  "OUT_FOR_DELIVERY" = "OUT_FOR_DELIVERY",
}

export enum AdditionalOrderStatusState {
  "ORDER_ENDED" = "ORDER_ENDED",
  "ORDER_FEEDBACK_RECEIVED" = "ORDER_FEEDBACK_RECEIVED",
  "ORDER_FEEDBACK_NOT_RECEIVED" = "ORDER_FEEDBACK_NOT_RECEIVED",
}

export const orderStatusMessages: Record<OrderStatusState | AdditionalOrderStatusState, string> = {
  ORDER_PLACED: "Scheduled",
  ORDER_CONFIRMED: "Confirmed by Restaurant",
  ORDER_PREPARING: "Being Prepared",
  ORDER_PREPARED: "Food Prepared",
  ORDER_OUT_FOR_DELIVERY: "Order Picked-up",
  ORDER_DELIVERED: "Order Arrived",
  ORDER_CANCELLED: "Order Cancelled",
  ORDER_UNDELIVERED: "Delivery Failed",
  ORDER_PARTIALLY_DELIVERED: "Partly Delivered",
  ORDER_PENDING: "Payment Pending",
  ORDER_ENDED: "Order ended",
  PICKUP_BOY_ALLOCATED: "Awaiting Pickup",
  OUT_FOR_PICKUP: "Awaiting Pickup",
  ORDER_PICKED_UP: "In Transit",
  DELIVERY_BOY_ALLOCATED: "In Transit",
  OUT_FOR_DELIVERY: "Order Picked-up",
  ORDER_FEEDBACK_NOT_RECEIVED: "Didn't Receive Food",
  ORDER_FEEDBACK_RECEIVED: "Order Received",
};

export const nextOrderStatusMessages: Record<OrderStatusState, string> = {
  ORDER_PLACED: "Order will be Scheduled",
  ORDER_CONFIRMED: "Order will be Confirmed by Restaurant",
  ORDER_PREPARING: "Order will start Being Prepared",
  ORDER_PREPARED: "Food Prepared",
  ORDER_OUT_FOR_DELIVERY: "Order Picked-up",
  ORDER_DELIVERED: "Order Arrived",
  ORDER_CANCELLED: "Order Cancelled",
  ORDER_UNDELIVERED: "Delivery Failed",
  ORDER_PARTIALLY_DELIVERED: "Partly Delivered",
  ORDER_PENDING: "Payment Pending",
  PICKUP_BOY_ALLOCATED: "Awaiting Pickup",
  OUT_FOR_PICKUP: "Awaiting Pickup",
  ORDER_PICKED_UP: "In Transit",
  DELIVERY_BOY_ALLOCATED: "In Transit",
  OUT_FOR_DELIVERY: "Order Picked-up",
};

export enum PaymentTypes {
  CASH_ON_DELIVERY = "CASH_ON_DELIVERY",
  PRE_PAID = "PRE_PAID",
}

export enum ContactTypes {
  HELPDESK = "HELPDESK",
  OUTLET = "OUTLET",
  VENDOR = "VENDOR",
  DELIVERY_PARTNER = "DELIVERY_PARTNER",
  NONE = "NONE",
}

export enum FeedbackTypes {
  RECEIVED = "RECEIVED",
  NOT_RECEIVED = "NOT_RECEIVED",
}

export enum ElementId {
  pnrInput = "pnr-input",
  pageContainer = "page-container",
  downloadAppSection = "download-app-section",
}

export enum PubSubEvents {
  LOAD_CAPTCHA = "LOAD_CAPTCHA",
}
