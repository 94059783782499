import { AppContext } from "@context/index";
import { PubSubEvents } from "@enums/common";
import useInitApp from "@hooks/useInitApp";
import usePassengerDetails from "@hooks/usePassengerDetails";
import { addUrlToHistoryStack, initializeHistoryStack } from "@services/utils/Common";
import { Config } from "@services/utils/Config";
import { DefaultSeo, DefaultSeoProps } from "next-seo";
import type { AppProps } from "next/app";
import { useRouter } from "next/router";
import Script from "next/script";
import PubSub from "pubsub-js";
import { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { SWRConfig } from "swr";
import "../styles/globals.css";
import RecaptchaScript from "./RecaptchaScript";
declare global {
  interface Window {
    grecaptcha: any;
    loadingGrecaptcha: boolean;
    adsbygoogle: any;
  }
}

const defaultTitle = "Order Food on Train Online, Food and Meal on Train, Tasty Food for Train Journey";
const defaultDescription =
  "Order Online Cheap Veg and Non-veg Food on Running Train & Enjoy Journey. Grab The Best Offers on Food in Train Food Delivery by Our Travel and Rail Khana Services.";
const defaultCanonical = "https://www.ecatering.irctc.co.in/";

const SEO: DefaultSeoProps = {
  title: defaultTitle,
  titleTemplate: "%s | eCatering IRCTC",
  description: defaultDescription,
  canonical: defaultCanonical,
  openGraph: {
    type: "website",
    locale: "en_IN",
    url: defaultCanonical,
    site_name: "IRCTC eCatering",
    title: defaultTitle,
    description: defaultDescription,
    images: [
      {
        url: "https://carbon-v2.ipsator.com/static/images/fot-oneplus-7-q-auto.png",
        width: 800,
        height: 600,
        alt: "Order Food Online On Train",
      },
    ],
  },
  twitter: {
    handle: "@ecateringirctc",
    site: "@ecateringirctc",
    cardType: "summary_large_image",
  },
  additionalMetaTags: [
    {
      name: "viewport",
      content: "width=device-width, initial-scale=1.0",
    },
    {
      name: "keywords",
      content:
        "IRCTC eCatering, IRCTC eCatering Official Website, Delivery Food Rail, Rail Khana, Food Delivery in Running Train, Food delivery on train, Food for Train Journey, Food on train, Food rail delivery, Khana Train, Online food delivery on train, online khana order on train, Order khana online, order online khana, railway food menu, Train Food, Travel khana, Cheap food delivery in train, Cheap food delivery on train seat, Food box delivery on train, Food for Travelling In Train, Food packet delivery on train, Fresh food delivery on train, Fresh food on train, Khana online, Online khana, Online khana order, Order food on train, Quick food delivery on train, Food aggregators on train stations, Food for Long Train Journey, Online food delivery, Order food online, Restaurant Train Delivers Food, Travel and Food Services, Travel food service, Travel Food Services, Travel Food Services India",
    },
    {
      name: "author",
      content: "IRCTC eCatering",
    },
    {
      name: "language",
      content: "English",
    },
    {
      name: "revisit-after",
      content: "7 days",
    },
    {
      name: "geo.region",
      content: "IN",
    },
    {
      name: "geo.position",
      content: "22.351115;78.667743",
    },
  ],
};

const AppContainer = ({ Component, pageProps }: any) => {
  const { loginUser, signupUser, isUserLoggedIn } = usePassengerDetails();
  useEffect(() => {
    if (!isUserLoggedIn) {
      const params = new URLSearchParams(window.location.search);
      const user = params.get("user");
      if (user) {
        const userDetails = atob(user).split(":");
        loginUser(userDetails[1], userDetails[2], {
          failureCB: () => {
            signupUser(userDetails[0], userDetails[1], userDetails[2]);
          },
        });
      }
    }
  }, []);
  return <Component {...pageProps} />;
};

const MoEngageScript = (appId: string, isProd = true) => {
  return `
  (function(i,s,o,g,r,a,m,n){i.moengage_object=r;t={};q=function(f){return function(){(i.moengage_q=i.moengage_q||[]).push({f:f,a:arguments})}};f=['track_event','add_user_attribute','add_first_name','add_last_name','add_email','add_mobile','add_user_name','add_gender','add_birthday','destroy_session','add_unique_user_id','moe_events','call_web_push','track','location_type_attribute'],h={onsite:["getData","registerCallback"]};for(k in f){t[f[k]]=q(f[k])}for(k in h)for(l in h[k]){null==t[k]&&(t[k]={}),t[k][h[k][l]]=q(k+"."+h[k][l])}a=s.createElement(o);m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m);i.moe=i.moe||function(){n=arguments[0];return t};a.onload=function(){if(n){i[r]=moe(n)}}})(window,document,'script','https://cdn.moengage.com/webpush/moe_webSdk.min.latest.js','Moengage')

    Moengage = moe({
      app_id:"${appId}",
      debug_logs: ${isProd ? 0 : 1},
      cluster: "DC_3"
    });
  `;
};

const RaygunScript = (appKey: string) => {
  return `
    !function(a,b,c,d,e,f,g,h){a.RaygunObject=e,a[e]=a[e]||function(){
    (a[e].o=a[e].o||[]).push(arguments)},f=b.createElement(c),g=b.getElementsByTagName(c)[0],
    f.async=1,f.src=d,g.parentNode.insertBefore(f,g),h=a.onerror,a.onerror=function(b,c,d,f,g){
    h&&h(b,c,d,f,g),g||(g=new Error(b)),a[e].q=a[e].q||[],a[e].q.push({
    e:g})}}(window,document,"script","//cdn.raygun.io/raygun4js/raygun.min.js","rg4js");

    rg4js('apiKey', "${appKey}");
    rg4js('enableCrashReporting', true);
    rg4js('enablePulse', true);
  `;
};

function MyApp({ Component, pageProps }: AppProps) {
  const lazyLoadLibs = true;
  const [loadCaptcha, setLoadCaptcha] = useState(!Config.lazyloadCaptcha);
  useInitApp(pageProps?.routeQuery);

  // console.log("pageProps", pageProps);

  const isWebview = pageProps?.browserInfo?.isWebview;

  const router = useRouter();

  const loadCaptchaOnSub = () => {
    if (!loadCaptcha) {
      setLoadCaptcha(true);
    }
  };

  useEffect(() => {
    initializeHistoryStack(router.asPath);
    PubSub.subscribeOnce(PubSubEvents.LOAD_CAPTCHA, loadCaptchaOnSub);
    return () => {
      PubSub.clearAllSubscriptions();
      // PubSub.unsubscribe(token);
    };
  }, []);

  useEffect(() => {
    console.log("setting loadCaptcha", loadCaptcha);
  }, [loadCaptcha]);

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      //need to change it according to current implementation
      // analytics.trackScreen(url);
      addUrlToHistoryStack(url);
    };
    //When the component is mounted, subscribe to router changes
    //and log those page views
    router.events.on("routeChangeComplete", handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  return (
    <SWRConfig
      value={{
        onErrorRetry: (error: any) => {
          // Never retry on 401.
          if (error.status === 401) return;
        },
        errorRetryCount: 2,
      }}
    >
      <>
        <DefaultSeo {...SEO} />

        {/* Google Analytics */}
        {/* {lazyLoadLibs ? (
          <Script
            id="ga-lib"
            strategy="lazyOnload"
            src="https://www.google-analytics.com/analytics.js"
            onLoad={() => {
              console.log("Google Analytics loaded");
            }}
          />
        ) : null}
        <Script
          id="ga-init"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
            window.GoogleAnalyticsObject = "ga";
            window.ga =
              window.ga ||
              function () {
                (window.ga.q = window.ga.q || []).push(arguments);
              };
            window.ga.l = +new Date();
            window.ga("create", "${Config.gaId}", "auto");
            `,
          }}
        /> */}

        <Script
          id="handling rejection"
          strategy="afterInteractive"
          onLoad={() => {
            const globalPromiseRejectionHandler = (event: any) => {
              console.error("Unhandled promise rejection reason: ", event.reason);
            };
            window.onunhandledrejection = globalPromiseRejectionHandler;
          }}
        />

        {/* Cloudflare Analytics */}
        {process.env.NEXT_PUBLIC_ENV === "prod" && process.env.NEXT_PUBLIC_CF_BEACON && lazyLoadLibs ? (
          <Script
            id="cfa-init"
            strategy="lazyOnload"
            src="https://static.cloudflareinsights.com/beacon.min.js"
            data-cf-beacon={`{"token": "${process.env.NEXT_PUBLIC_CF_BEACON}"}`}
          />
        ) : null}

        {/* Google Recaptcha */}
        {/* <Script
          id="recaptcha-init"
          strategy="beforeInteractive"
          dangerouslySetInnerHTML={{
            __html: `
          window.loadingGrecaptcha = true;
          `,
          }}
        /> */}

        {/* strategy={pageProps?.loadCaptcha ? "afterInteractive" : "lazyOnload"} */}

        {/* to do : Need to add setTime out here */}
        {loadCaptcha || pageProps?.loadCaptcha ? <RecaptchaScript {...{ pageProps, Config }} /> : null}

        {/* MoEngage */}
        {process.env.NEXT_PUBLIC_MOENGAGE_ENABLED === "true" && process.env.NEXT_PUBLIC_MOENGAGE_KEY && !isWebview ? (
          <Script
            id="moengage-init"
            strategy={"afterInteractive"}
            dangerouslySetInnerHTML={{ __html: MoEngageScript(process.env.NEXT_PUBLIC_MOENGAGE_KEY, process.env.NEXT_PUBLIC_ENV === "prod") }}
          />
        ) : null}

        {/* Raygun */}
        {process.env.NEXT_PUBLIC_RAYGUN_ENABLED === "true" && process.env.NEXT_PUBLIC_RAYGUN_API_KEY ? (
          <Script
            id="raygun-init"
            // strategy={"afterInteractive"}
            dangerouslySetInnerHTML={{
              __html: RaygunScript(process.env.NEXT_PUBLIC_RAYGUN_API_KEY),
            }}
          />
        ) : null}

        {/* Google Adsense */}
        {/* <Script
          id="adsense-lib"
          strategy="afterInteractive"
          src={"https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=" + Config.adsenseClient}
          // onLoad={() => {
          //   (window.adsbygoogle = window.adsbygoogle || []).push({});
          // }}
        /> */}
        <AppContext>
          <div>
            <AppContainer Component={Component} {...{ pageProps }} />
          </div>
        </AppContext>
      </>
    </SWRConfig>
  );
}

export default MyApp;
