import { Config } from "./utils/Config";

const waitForCaptcha = async (): Promise<void> => {
  return new Promise((resolve, reject) => {
    let retryCounter = 0;
    const maxRetries = 30;

    const checkGrecaptcha = () => {
      if (window.grecaptcha) {
        resolve();
      } else if (retryCounter < maxRetries) {
        setTimeout(checkGrecaptcha, 1000);
        retryCounter++;
      } else {
        reject(new Error("Failed to load grecaptcha after 30 attempts"));
      }
    };

    checkGrecaptcha();
  });
};

export const getCaptchaToken = async (action = "homepage"): Promise<string> => {
  try {
    await waitForCaptcha();
    const token = await window.grecaptcha?.execute?.(Config.gToken, { action });
    return token || "";
  } catch (error) {
    console.log(error);
    return "";
  }
};
