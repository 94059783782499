type PropType = {
  text: string;
};

function Snackbar({ text }: PropType) {
  // return isMobile ? (
  return (
    // <div id={"toast-container"} className={"fixed bottom-1 left-2/4 w-max max-w-70 lg:max-w-max transform -translate-x-2/4 z-50"}>
    <div className={"rounded bg-gray-70 p-4 px-4 text-sm text-white"}>{text}</div>
    // </div>
    // ) : (
    //   <div id={"toast-container"} className={"fixed top-20 right-0 z-50"}>
    //     <div className={"mx-2 bg-gray-50 text-sm text-white p-4 px-8 rounded"}>{text}</div>
    //   </div>
  );
}

export default Snackbar;
