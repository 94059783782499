import Analytics from "@services/analytics";
import { PersistantStorage, Storage } from "@services/utils/Storage";
import router from "next/router";
import { cache } from "swr";

export const Logout = (successCb: () => void) => {
  Analytics.moengageAttribute()?.logout();
  PersistantStorage.clearAll();
  cache.clear();
  Storage.clearAll();
  router.replace("/").then(() => {
    successCb();
  });
};
