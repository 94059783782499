import useCommonContext from "@hooks/useCommonContext";
import usePnrContext from "@hooks/usePnrContext";
import { getContextFromStorage, removeFunctionsFromObj, storeContextInStorage } from "@services/utils/Common";
import { useRouter } from "next/router";
import React, { useEffect } from "react";
import { CommonContextProvider, CommonContextReducerState, getInitialPnrReducerState, PnrContextProvider, PnrContextReducerState } from ".";
import { getInitialCommonContextState } from "./Common/commonContextReducer";

const ContextHandler = () => {
  const router = useRouter();
  const { loadState: loadCommonContextState, setMounted, mounted, ...commonContextState } = useCommonContext();
  const { loadState: loadPnrContextState, ...pnrContextState } = usePnrContext();

  const initializePnrContext = (storedPnrState: PnrContextReducerState | undefined) => {
    // pnrContext is not loaded in /[pnr]/outlets to make a new pnr call on opening /pnr/outlets page directly
    if (router.pathname !== "/" && router.pathname !== "/[pnr]/outlets" && !!storedPnrState) {
      const { vendorId, outletId, pnr, stationCode } = router.query;
      const { pnrInfo, outletInfo, stationInfo } = storedPnrState;
      // if pnr exists in router query, validate pnrInfo with pnr in query
      if (!!pnr && !!pnrInfo.pnr && pnr !== pnrInfo.pnr) {
        storedPnrState.pnrInfo = getInitialPnrReducerState().pnrInfo;
      }
      // if outletId and vendorId exists in the router query, validate the outletinfo with their ids
      if (!!outletId && !!vendorId && !!outletInfo) {
        if (outletInfo?.id !== parseInt(outletId as string) && outletInfo.vendorId !== parseInt(vendorId as string)) {
          storedPnrState.outletInfo = null;
        }
      }
      // if stationCode exists in router query, validate stationInfo with stationCode in query
      if (!!stationCode && !!stationInfo) {
        if (stationInfo.code !== stationCode) {
          storedPnrState.stationInfo = null;
        }
      }
      loadPnrContextState?.(storedPnrState);
    }
  };

  const initializeCommonContext = (storedCommonContextState: CommonContextReducerState | undefined) => {
    const commonContext = getInitialCommonContextState();

    if (storedCommonContextState?.userInfo) {
      commonContext.userInfo = storedCommonContextState.userInfo;
    }

    loadCommonContextState?.(commonContext);
  };

  useEffect(() => {
    const storedContext = getContextFromStorage();
    initializePnrContext(storedContext.pnrContext);
    initializeCommonContext(storedContext.commonContext);
    setMounted?.(true);
  }, []);

  useEffect(() => {
    if (mounted) {
      storeContextInStorage(removeFunctionsFromObj(pnrContextState) as any, removeFunctionsFromObj(commonContextState) as any);
    }
  }, [mounted, commonContextState, pnrContextState]);

  return <></>;
};

export const AppContext: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <CommonContextProvider>
      <PnrContextProvider>
        <ContextHandler />
        {children}
      </PnrContextProvider>
    </CommonContextProvider>
  );
};

export default AppContext;
