import { CommonContextReducerActions, CommonContextReducerState } from ".";

export const getInitialCommonContextState = (): CommonContextReducerState => ({
  mounted: false,
  userInfo: null,
  userModal: {
    show: false,
    type: "login",
  },
});

// @ts-ignore
const _keyRef: Record<CommonContextReducerActions["type"], keyof CommonContextReducerState> = {
  SET_MOUNTED: "mounted",
  SET_USER_INFO: "userInfo",
  SET_USER_MODAL_INFO: "userModal",
};

export const commonContextReducer = (state: CommonContextReducerState, action: CommonContextReducerActions): CommonContextReducerState => {
  switch (action.type) {
    case "SET_MOUNTED":
    case "SET_USER_INFO":
    case "SET_USER_MODAL_INFO":
      return { ...state, [_keyRef[action.type]]: action.payload };
    case "LOAD_STATE":
      return { ...state, ...action.payload };
    case "RESET_STATE":
      return { ...getInitialCommonContextState(), mounted: true };
  }
};
