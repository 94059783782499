import { FormFields } from "@components/Forms/UserForm";
import { EventName } from "@enums/events";
import { CouponInfo, User } from "@interfaces/index";
import Analytics from "@services/analytics";
import { CreatePhonePeUser, GetPhonePeSSOGrantToken } from "@services/payments/phonepe";
import { BrowserInfo, LoginUser, SignupUser } from "@services/utils/Common";
import { Logout } from "@utils/Logout";
import { toast } from "@utils/Notification";
import { useMemo } from "react";
import useCommonContext from "./useCommonContext";
import usePnrContext from "./usePnrContext";

export type usePassengerDetails = () => {
  fullName?: string;
  mobile?: string;
  email?: string;
  coupons?: CouponInfo[];
  id?: number;
  refreshUserInfo: (arg0?: { fullName: string; mobile: string; email: string; successCb?: () => void }) => void;
  loginUser: (mobile: string, email: string, arg0?: { successCB?: (user: User) => void; failureCB?: (failed: boolean) => void }) => void;
  signupUser: (mobile: string, email: string, fullName: string, arg0?: { successCB?: (user: User) => void; failureCB?: () => void }) => void;
  logout: () => void;
  loginPhonePe: (loginOnSuccess: boolean, arg0: { successCB?: (user: FormFields) => void; showToastOnFailure?: boolean }) => void;
  isUserLoggedIn: boolean;
};

type PassengerDetailsType = ReturnType<usePassengerDetails>;

const usePassengerDetails: usePassengerDetails = () => {
  const { userInfo, setUserInfo, resetState: resetCommonState } = useCommonContext();
  const { resetState: resetPnrState } = usePnrContext();

  const authFailedCb = () => {
    toast.notify("Invalid credentials. Please try again.");
  };

  const loginUser: PassengerDetailsType["loginUser"] = (mobile, email, { successCB, failureCB } = {}) => {
    LoginUser(mobile, email, {
      successCB: (user) => {
        setUserInfo?.(user);
        successCB?.(user);
      },
      failureCB,
    });
  };

  const loginPhonePe: PassengerDetailsType["loginPhonePe"] = (loginOnSuccess, { successCB, showToastOnFailure = true }) => {
    if (BrowserInfo?.phonepeEnv) {
      GetPhonePeSSOGrantToken().then((ssoResponse) => {
        if (ssoResponse.hasOwnProperty("grantToken")) {
          CreatePhonePeUser({
            grantToken: ssoResponse.grantToken,
          }).then((data) => {
            if (data.status === "success") {
              const userInfo: FormFields = {
                mobile: data.result.data.userDetails.phoneNumber,
                email: data.result.data.userDetails.primaryEmail,
                fullName: data.result.data.userDetails.name,
              };
              if (loginOnSuccess) {
                loginUser(data.result.data.userDetails.phoneNumber, data.result.data.userDetails.primaryEmail, {
                  failureCB: showToastOnFailure ? authFailedCb : undefined,
                  successCB,
                });
              } else {
                successCB?.(userInfo);
              }
            }
          });
        }
      });
    }
  };

  const logout = () => {
    Logout(() => {
      resetCommonState?.();
      resetPnrState?.();
    });
  };

  const signupUser: PassengerDetailsType["signupUser"] = (mobile, email, fullName, { successCB, failureCB } = {}) => {
    SignupUser(fullName, mobile, email, {
      successCB: (user: User) => {
        // dont trigger if user name is being updated
        Analytics.track(EventName.SIGN_UP);
        Analytics.moengageEvent()?.sign_up();
        setUserInfo?.(user);
        successCB?.(user);
      },
      failureCB,
    });
  };

  const refreshUserInfo = (userInfoArg: Parameters<PassengerDetailsType["refreshUserInfo"]>[0]) => {
    if (userInfoArg) {
      signupUser(userInfoArg.mobile, userInfoArg.email, userInfoArg.fullName, { successCB: userInfoArg.successCb });
    } else if (userInfo) {
      loginUser(userInfo.mobile, userInfo.email);
    }
  };

  const __passengerDetails = useMemo(() => {
    return { ...(userInfo ?? {}), isUserLoggedIn: !!userInfo?.id, refreshUserInfo, loginUser, signupUser, logout, loginPhonePe };
  }, [userInfo]);

  return __passengerDetails;
};

export default usePassengerDetails;
